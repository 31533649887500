@import './credit-card.css';
@import './donate-box.css';
@import './form.css';

* {
  box-sizing: border-box;
  font-family: Poppins, sans-serif;
  margin: 0;
  padding: 0;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.sandbox {
  border-bottom: solid 5px;
  border-image: repeating-linear-gradient(
      -90deg,
      yellow,
      yellow 10px,
      black 10px,
      black 20px
    )
    20;
}

a {
  color: #000000;
}

.app {
  display: flex;
  flex-flow: column;
  color: #0a0a0a;
  align-items: center;
}

.admin-header {
  padding: 2px 0px;
  background-color: #242424;
  display: flex;
  justify-content: center;
  width: 100%;
}

.admin-header ul {
  list-style: none;
}

.admin-header ul li {
  display: block;
  float: left;
  padding: 0 10px 2px 10px;
}

.admin-header ul li a {
  color: white;
  text-decoration: none;
}

.admin-header ul li button {
  background: none;
  color: white;
  border: none;
  font-size: 16px;
  padding: 0;
  padding-right: 10px;
}

.header {
  padding: 52px 0px;
  background-color: #e6f0f0;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 50px;
  margin-bottom: 80px;
}

.header__content {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.header__title {
  font-size: 1.3rem;
  font-weight: 500;
  margin-left: 2em;
}

.header__logo {
  height: 128px;
  width: 128px;
  pointer-events: none;
  padding: 20px;
  margin-bottom: -5em;
}

@media (max-width: 800px) {
  .header__title {
    display: none;
  }

  .header__content {
    justify-content: center;
  }
}

.donate-section {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 20px;
}

.btn {
  padding: 10px 20px;
  border: none;
  border-radius: 25px;
  background-color: #2afc83;
  font-size: 16px;
  cursor: pointer;
  font-weight: bold;
}

.btn:hover,
.btn:focus {
  border-color: #3d9cff;
  box-shadow: 1px 2px 3px 1px rgba(0, 212, 255, 0.4);
}

.btn:disabled {
  background: #ccc;
  border-color: #ccc;
  box-shadow: none;
}

.loading-box {
  display: flex;
  flex-flow: column;
  max-width: 400px;
  border-radius: 5px;
  width: 350px;
  padding: 20px;
  min-height: 750px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #666;
}

.loading-box__img {
  margin: 30px;
}

.error-box {
  margin: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.error-box__msg {
  text-align: center;
}

.error-box__btn-container {
  margin-top: 15px;
}

.footer__back_link {
  margin-top: 1em;
}

.footer__version {
  font-size: 12px;
  color: #666666;
}

.footer {
  margin-top: 250px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background-color: #e6f0f0;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 300px;
}

.footer .footer_content {
  margin-top: 2em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.footer .footer_content a {
  text-decoration: none;
  cursor: pointer;
}

.footer .footer_content a:hover {
  text-decoration: underline;
}
