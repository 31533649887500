.donate-box {
  display: flex;
  flex-flow: column;
  min-width: 300px;
  max-width: 400px;
  border: solid 1px #dddddd;
  box-shadow: 1px 1px 10px #e4eeee;
  border-radius: 5px;

  -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 1s; /* Firefox < 16 */
  -ms-animation: fadein 1s; /* Internet Explorer */
  -o-animation: fadein 1s; /* Opera < 12.1 */
  animation: fadein 1s;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.donate-box--creating {
  opacity: 0.5;
  pointer-events: none;
}

.donate-box__title {
  background: #e4eeee;
  padding: 10px 20px;
  font-size: 16px;
}

.donate-box__sandbox-notice {
  text-align: center;
  padding: 10px;
  font-size: 13px;
}

.donate-box__amounts {
  display: grid;
  padding: 20px;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 0px;
}

.donate-box__amount {
  position: relative;
  margin: 5px 5px;
  border: 1px solid #e4eeee;
  background: white;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 1px 1px 5px #e4eeee;
}

.donate-box__amount:hover {
  background-color: #e4eeee;
  box-shadow: 1px 1px 5px #ccc;
  transition: all 0.15;
}

.donate-box__amount--active {
  background-color: #e4eeee;
  border: 1px solid #dddddd;
}

.donate-box__amount-confetti {
  position: absolute !important;
  top: 50%;
  left: 50%;
  z-index: 99999;
}

.donate-box__footer {
  padding: 10px 50px;
}

.donate-box__footer-inner img {
  max-width: 100%;
}

.donate-box .feedback {
  font-size: 11px;
  color: #cc0000;
  background-color: #fff2f2;
  border: solid 1px #cc0000;
  padding: 5px 10px;
  margin-bottom: 5px;
}
