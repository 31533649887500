.credit-card {
  background-color: transparent;
  perspective: 1000px;
  width: 272px;
  background: transparent;
  height: 156px;
  margin: auto;
}

.credit-card__inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.credit-card__inner--transform {
  transform: rotateY(180deg);
}

.credit-card__front {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 15px 30px 0 #e4eeee;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  background: linear-gradient(127deg, #4affa3, #2adf83);
}

.credit-card__row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.credit-card__chip {
  display: flex;
  flex-grow: 1;
}

.credit-card__chip-img {
  height: 60px;
}

.credit-card__card-type {
  align-self: flex-start;
  flex-grow: 1;
  text-align: right;
}

.credit-card__number {
  display: flex;
  flex-direction: row;
}

.credit-card__number-item {
  font-size: 15px;
  transition: font-size 0.2s;

  display: inline-block;
  width: 16px;
}

/* TODO: fix this style to reset the size of the number */
.credit-card__number-transform {
  font-size: 20px;
}

.credit-card__number-separator {
  color: transparent;
  width: 10px;
}

.credit-card__back {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  padding: 10px;
  margin-left: -20px;
  box-shadow: 0 15px 30px 0 #e4eeee;
  backface-visibility: hidden;
  background: linear-gradient(127deg, #4affa3, #2adf83);
  transform: rotateY(180deg);
}

.credit-card__band {
  width: 100%;
  height: 25px;
  background-color: black;
}

.credit-card__cvv {
  margin-top: 25px;
}

.credit-card__cvv-label {
  text-align: right;
}
.credit-card__cvv-field {
  width: 100%;
  height: 25px;
  border-radius: 5px;
  background-color: white;
}
