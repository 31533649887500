.form {
  display: flex;
  margin-top: 50px;
  padding: 15px;
  flex-direction: column;
  /* change autocomplete background style */
}

.form__input,
.form__select {
  margin: 1px 5px 10px 0;
  padding: 10px 5px 10px 5px;
  border: 1px solid #ccc;
  transition: all 0.35s cubic-bezier(0.71, 0.03, 0.56, 0.85);
}

.form__input {
  width: 100%;
}

.form__input:hover,
.form__input:focus,
.form__select:hover,
.form__select:focus {
  border-color: #3d9cff;
  box-shadow: 1px 2px 3px 1px rgba(0, 212, 255, 0.4);
}

.form__input:-webkit-autofill,
.form__input:-webkit-autofill:hover,
.form__input:-webkit-autofill:focus {
  box-shadow: 0 0 0px 1000px #fff inset;
  -webkit-box-shadow: 0 0 0px 1000px #fff inset;
  transition: background-color 5000s ease-in-out 0s;
}

.form__label {
  font-size: 12px;
  text-align: left;
}

.form__submit-btn-container {
  margin: 20px;
  display: flex;
  justify-content: center;
}

/* credit card form styles */
.form__expiration-column {
  display: flex;
  flex-direction: column;
}

.form__expiration-row {
  display: grid;
  grid-template-columns: 2fr 1fr;
}

.form__card-date-select {
  width: 100px;
}

.form__terms {
  font-size: 13px;
  padding: 10px 0;
  text-align: center;
}
